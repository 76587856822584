@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  @apply bg-gray-900 text-gray-100 flex flex-col h-full min-h-screen;
}

.github {
  width: 32px;
  height: 32px;
  background-image: url("images/GitHub-Mark-Light-120px-plus.png");
  background-size: contain;
}

.modal {
  transition: opacity 0.25s ease;
}

.modal a {
  @apply text-green-600;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}
